exports.shouldUpdateScroll = ({ pathname, routerProps, prevRouterProps }) => {
  const resetScrollPositionLogic = (clear = true) => {
    clear && localStorage.removeItem('pinkertonScrollTo');
    window.scrollTo(0, 0);
    return false;
  };
  if (!prevRouterProps) return resetScrollPositionLogic();

  const previousPath = prevRouterProps.location.pathname;
  const hasHash = !!(
    routerProps.location.hash && routerProps.location.hash.indexOf('#') === 0
  );
  const nextPath = pathname;

  if (
    !pathname.includes('our-insights') &&
    !pathname.includes('press-and-events/press')
  ) {
    if (hasHash) {
      return true;
    }
    return resetScrollPositionLogic();
  }
  if (previousPath.includes('analysis')) return resetScrollPositionLogic();

  const commingFromTheSameParentRoute =
    previousPath.split('/')[2] === nextPath.split('/')[2];
  const theRouteIsSingleEntry = nextPath.split('/')[3];

  if (commingFromTheSameParentRoute) {
    if (theRouteIsSingleEntry) {
      resetScrollPositionLogic(false);
    } else {
      let pinkertonScrollTo = parseInt(
        localStorage.getItem('pinkertonScrollTo'),
      );
      return !isNaN(pinkertonScrollTo);
    }
  } else {
    resetScrollPositionLogic();
  }

  return false;
};

exports.onRouteUpdate = () => {
  const links = document.querySelectorAll('.main a[href^="http"]');

  links.forEach((link) => {
    if (link.hostname !== window.location.hostname) {
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
    }
  });
};
