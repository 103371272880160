module.exports = [{
      plugin: require('../docker-cache/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pinkerton","short_name":"pinkerton","start_url":"/","display":"browser","icon":"static/pnk-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e5e94754895967bd80d366f464a632cc"},
    },{
      plugin: require('../docker-cache/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
